@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "DM Sans";
    src: url("../public/fonts/DMSans-Regular.ttf");
}

@font-face {
    font-family: "DM Sans Italic";
    src: url("../public/fonts/DMSans-Italic.ttf");
}

@font-face {
    font-family: "DM Sans Bold";
    src: url("../public/fonts/DMSans-Bold.ttf");
}

@font-face {
    font-family: "DM Sans Bold Italic";
    src: url("../public/fonts/DMSans-BoldItalic.ttf");
}

@font-face {
    font-family: "DM Sans Medium";
    src: url("../public/fonts/DMSans-Medium.ttf");
}

@font-face {
    font-family: "DM Sans Medium Italic";
    src: url("../public/fonts/DMSans-MediumItalic.ttf");
}

@font-face {
    font-family: "DM Sans Regular";
    src: url("../public/fonts/DMSans-Regular.ttf");
}